import React from 'react';
import PropTypes from 'prop-types';
import { Space, Card } from 'antd';
import CodeMirror from '@uiw/react-codemirror';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/idea.css';
// import 'codemirror/theme/monokai.css';
import "codemirror/lib/codemirror.css";
import 'codemirror/theme/eclipse.css';

import './../css/style.css';
import './../css/border.css';
// import jsonToGo from './../lib/json-to-go';


class CodeInput2 extends React.Component {
    static propTypes = {
        syntax: PropTypes.string,
        current: PropTypes.string,
        mapping: PropTypes.object,
        options: PropTypes.object,
        onCodeChange: PropTypes.func.isRequired,
        onCodeLastChange: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);
        this.state = {
            language: ""
        }

        this.onChange = this.onChange.bind(this);
        this.editorDidMount = this.editorDidMount.bind(this);
    }

    componentDidUpdate(prevProps) {
        let changed = this.props.options[this.props.syntax].code != prevProps.options[this.props.syntax].code;
        if (changed) {
            this.props.onCodeLastChange(this.props.syntax);
        }
    }

    editorDidMount(editor, monaco) {
        // editor.focus();  // 不要自动focus哦
        const lan = this.props.mapping[this.props.syntax];
        this.setState({ language: lan });
        var codeOption = this.props.options
        let showCode = ""
        Object.keys(this.props.options).forEach(function (item, index) {
            if (lan === codeOption[item].label) {
                showCode = decodeURIComponent(atob(codeOption[item].code));
            }
        });
        editor.setValue(showCode)
    }

    onChange(inst, change) {
        if ((change.from.ch < 1 && change.from.line < 1 && change.to.ch < 1 && change.to.line < 1) && change.origin != "paste") {
            // 没有改变，别触发
        } else {
            this.props.onCodeChange(inst.getValue(), undefined, this.props.syntax);
        }
    }

    render() {
        const options = {
            selectOnLineNumbers: false,
            wordWrapMinified: false,
            minimap: {
                enabled: false,
            }
        };
        const spaceSize = 'small';
        const cardWidth = 500;
        const editorWidth = cardWidth - 27;
        const cardHeight = 360;
        const editorHeight = cardHeight - 85;

        let showCode = "";
        var lan = this.props.syntax;

        const lang = this.props.mapping[this.props.syntax];
        var codeOption = this.props.options
        Object.keys(this.props.options).forEach(function (item, index) {
            if (lan === codeOption[item].label) {
                showCode = decodeURIComponent(atob(codeOption[item].code));
            }
        });

        let clsName = this.props.current == this.props.syntax ? "box" : "nobox";

        return (
            <div className="space-align-block">
                <Space align="center" size={spaceSize}>
                    {/* <TextArea rows={10} /> */}
                    <Card className={clsName} title={this.props.syntax} style={{ width: cardWidth, height: cardHeight, margin: '0px 20px' }}>

                        <CodeMirror
                            value={showCode}
                            autoScroll={true}
                            // width={'50%'}
                            height={''}
                            // lazyLoadMode={false}
                            maxHeight='10px'
                            options={{
                                keyMap: 'sublime',
                                mode: lang,
                                theme: 'idea',
                                tabSize: 2,

                            }}
                            onChange={this.onChange}
                        />

                    </Card>
                </Space>
            </div>
        )
    }
}

export default CodeInput2;