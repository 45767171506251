import React, { useState } from 'react';
import { Row, Col, Form, Icon, Input, Button, Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import PropTypes from 'prop-types';

const { TextArea } = Input;

class CurlToLocal extends React.Component {
    formRef = React.createRef();

    static propTypes = {
        loading: PropTypes.bool,
        onTransfer: PropTypes.func.isRequired,
        value: PropTypes.object,
    }

    constructor(props) {
        super(props);
        this.state = {
            value: {},
            curl: {},
        }

        // this.formRef = React.createRef() // 第一步

        this.onChange = this.onChange.bind(this);
        this.getEditData = this.getEditData.bind(this);
    }


    componentDidMount() {
        this.getEditData(this.props);
    }

    componentDidMount() {
        // this.formRef.current.setFieldsValue({
            // username: 'Bamboo',
            // schema: theProps.schema,
            // hostName: theProps.hostName,
            // port: theProps.port,
            // referer: theProps.referer,
            // origin: theProps.origin,
            // curl: theProps.curl,
        // });
    }

    getEditData(theProps) {
        console.log('getetgegeg');
        console.log(theProps);
        console.log(this.formRef);
        if (this.formRef.current === null) {
            return
        }
        // this.formRef.current.setFieldsValue({ // 第二步，name名字对应上
        //     schema: theProps.schema,
        //     hostName: theProps.hostName,
        //     port: theProps.port,
        //     referer: theProps.referer,
        //     origin: theProps.origin,
        //     curl: theProps.curl,
        // })
    }

    onChange(curl) {
        console.log('haahah')
        let newState = this.state;
        newState.curl = curl;
        this.setState(newState);
    }

    onFinish(values) {
        console.log('Success:', values);
    }

    onFinishFailed(errorInfo) {
        console.log('Failed:', errorInfo);
    }

    onGenderChange = (row, value) => {
        // switch (row) {
        // case 'schema':

        //     case 'hostName':
        //         case 'prot':
        //             case 'origin':
        //                 case 'curl':

        //   case 'male':
        //     form.setFieldsValue({
        //       note: 'Hi, man!',
        //     });
        //     return;

        //   case 'female':
        //     form.setFieldsValue({
        //       note: 'Hi, lady!',
        //     });
        //     return;

        //   case 'other':
        //     form.setFieldsValue({
        //       note: 'Hi there!',
        //     });
        // }
    }

    handleChange = e => {
        const number = parseInt(e.target.value || 0, 10);
        if (isNaN(number)) {
            return;
        }
        this.triggerChange({ number });
    };

    handleSchemaChange = schema => {
        this.triggerChange({ schema });
    };

    handleHostNameChange = hostName => {
        this.triggerChange({ hostName });
    };

    handlePortChange = port => {
        this.triggerChange({ port });
    };

    handleOriginChange = origin => {
        this.triggerChange({ origin });
    };

    handleRefererChange = referer => {
        this.triggerChange({ referer });
    };


    handleCurlChange = curl => {
        this.triggerChange({ curl });
    };

    triggerChange = changedValue => {
        const { onChange, value } = this.props;
        if (onChange) {
            onChange({
                ...value,
                ...changedValue,
            });
        }
    };

    render() {
        console.log(this.props);
        let theCurl = this.props.value.curl;
        let formTitle = (undefined != this.props.title) ? this.props.title : '转换cURL';
        const titleStyle = { textAlign: 'center' }


        let button = <Button type="primary" htmlType="submit" onClick={this.props.onTransfer}>转换cURL<LoadingOutlined /></Button>
        if (this.props.loading) {
        } else {
            button = <Button type="primary" htmlType="submit" onClick={this.props.onTransfer}>转换cURL</Button>
        }

        return (
            <>
                <h2 style={titleStyle}>{formTitle}</h2>
                <Form
                    name="basic"
                    labelCol={{
                        span: 4,
                    }}
                    wrapperCol={{
                        span: 20,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Schema"
                        name="schema"
                        rules={[
                            { required: false, message: 'Please input Http Schema' }
                        ]}>
                        <Input placeholder='Please input Http Schema' onChange={this.handleSchemaChange} />
                    </Form.Item>

                    <Form.Item
                        label="HostName"
                        name="hostName"
                        rules={[
                            { required: false, message: 'Please input Http Hostname, like: www.itjsz.com' }
                        ]}>
                        <Input placeholder='Please input Http Hostname' onChange={this.handleHostNameChange} />
                    </Form.Item>

                    <Form.Item
                        label="Port"
                        name="Port"
                        rules={[
                            { required: false, message: 'Please input Http Port' }
                        ]}>
                        <Input placeholder='Please input Http Port' onChange={this.handlePortChange} />
                    </Form.Item>

                    <Form.Item
                        label="Referer"
                        name="Referer"
                        rules={[
                            { required: false, message: 'Please input Http Header Referer' }
                        ]}>
                        <Input placeholder='Please input Http Header Referer' onChange={this.handleRefererChange} />
                    </Form.Item>

                    <Form.Item
                        label="Origin"
                        name="Origin"
                        rules={[
                            { required: false, message: 'Please input Http Header Origin' }
                        ]}>
                        <Input placeholder='Please input Http Header Origin' onChange={this.handleOriginChange} />
                    </Form.Item>

                    <Form.Item
                        label="cURL"
                        name="curl"
                        rules={[
                            { required: true, message: 'Please paste cURL!' }
                        ]}>
                        <TextArea
                            ref={this.formRef}
                            value={theCurl}
                            onChange={this.onChange}
                            placeholder="Controlled autosize"
                            autoSize={{ minRows: 5, maxRows: 12 }}
                            placeholder='Please paste cURL!'
                            onChange={this.handleCurlChange}
                        >{theCurl}</TextArea>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        {button}
                    </Form.Item>
                </Form>
            </>
        )
    }
}

export default CurlToLocal;