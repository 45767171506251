import React from 'react';
import PropTypes from 'prop-types';
import { For } from 'react-loops';
import './../css/style.css';
import ShowCode from './ShowCode';

class ShowCodes extends React.Component {
    static propTypes = {
        checkedList: PropTypes.array,
        codes: PropTypes.object,
        langMap: PropTypes.object
    }

    constructor(props) {
        super(props);
    }

    render() {
        let theItems = this.props.checkedList.map((item,index) => {
            // 把 value的值放到 code 里面就解决 不随setState 更新问题了
            return <ShowCode lang={this.props.langMap[item]} key={index+item} code={this.props.codes[this.props.menuKey][item]}>
                {this.props.codes[this.props.menuKey][item]}
            </ShowCode>
        })

        return (
            <div className="space-align-container">
                {/* For 循环里面的 lang 会更新，但是 value 字段内的不会随 state 更新而更新 */}
                {/* <For of={this.props.checkedList} extraVal={this.props.menuKey}
                    as={item => <ShowCode lang={this.props.langMap[item]}>
                        {this.props.codes['a' + this.props.menuKey][item]}
                    </ShowCode>} /> */}
                {theItems}
            </div>
        )
    }
}

export default ShowCodes;