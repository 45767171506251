import React from 'react';
import { Switch, withRouter, Link, Route } from 'react-router-dom'
import { Layout, Menu, Breadcrumb } from 'antd';
import MainContent from './MainContent';
import SyntaxMainContent from './SyntaxMainContent';
import CurlToLocal from './CurlToLocal';
import About from './About';

import * as Style from '../css/style.css';

const { Header, Content, Footer } = Layout;

const MuWithRouter = withRouter(props => <Antd {...props} />)

const GetBaidu = props => {
  let children = props.children;
  let _hmt = undefined;
  if (undefined == _hmt) {
    _hmt = [];
  }
  (function () {
    var hm = document.createElement("script");
    hm.src = "http://tongji.itjsz.com/hm.js?95bebaa36e6b2f1915df9b454fc0795f";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
  return children;
};


class Antd extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let tapNum = '1';
    if (String(this.props.location.pathname).startsWith('/syntax-diff')) {
      tapNum = '2';
    } else if (String(this.props.location.pathname).startsWith('/curl-to-local')) {
      tapNum = '4';
    } else if (String(this.props.location.pathname).startsWith("/about")) {
      tapNum = '3';
    } else {
      tapNum = '1';
    }

    return (
      <Layout className="layout">
        <Header>
          <div className="logo" />
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[tapNum]}
            selectedKeys={[tapNum]} style={{ lineHeight: '64px' }}>
            <Menu.Item key="1"><Link to="/">代码转换工具</Link></Menu.Item>
            <Menu.Item key="4"><Link to="/curl-to-local">CURL转本地</Link></Menu.Item>
            <Menu.Item key="2"><Link to="/syntax-diff">代码语法对照</Link></Menu.Item>
            <Menu.Item key="3"><Link to="/about">关于</Link></Menu.Item>
          </Menu>
        </Header>
        <Content>
          {/* <Content style={{ padding: '5px 10px' }}> */}
          {/* <Breadcrumb style={{ margin: '16px 10px' }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>App</Breadcrumb.Item>
          </Breadcrumb> */}
          <Switch>
            <GetBaidu>
              <Route path="/syntax-diff">
                <SyntaxMainContent className="site-layout-content">Content</SyntaxMainContent>
              </Route>
              <Route path="/curl-to-local">
                <CurlToLocal className="site-layout-content">Content</CurlToLocal>
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/">
                <MainContent className="site-layout-content" pathname={this.props.location.pathname}>Content</MainContent>
              </Route>
            </GetBaidu>
          </Switch>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Ant Design ©2022 | <a href="https://beian.miit.gov.cn/" rel="external nofollow noopener noreferrer" target="_blank">蜀ICP备20003424号-1</a></Footer>
      </Layout>
    )
  }
}

export default Antd;