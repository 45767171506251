import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { BarsOutlined, ContainerOutlined, ApartmentOutlined, BuildOutlined, CalculatorOutlined, LineChartOutlined, DiffOutlined, BlockOutlined } from '@ant-design/icons';

import CheckLangBar from './CheckLangBar';
import ShowCodes from './ShowCodes';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

let theCodes = {
    introduce: {
        "JavaScript": `console.log("hello world!");`,
        "PHP": `echo "hello world!";`,
        "Go": `package main\n\nimport "fmt"\n\nint main() {\n\tfmt.Println("hello world!");\n}`,
    },
    install: {
        "JavaScript": `\\\\ 下载个浏览器就是browser环境\n\\\\ 或者访问 https://nodejs.org/zh-cn/download/ 下载就是 node 环境`,
        "PHP": `\\\\到 https://www.php.net/downloads 下载`,
        "Go": `\\\\ 到 https://golang.org/dl/ 下载安装`,
    },
    hello_world: {
        "JavaScript": `console.log("hello world!");`,
        "PHP": `echo "hello world!";`,
        "Go": `package main\n\nimport "fmt"\n\nint main() {\n\tfmt.Println("hello world!");\n}`,
    },
    complie: {
        "JavaScript": `\\\\ 无需编译即可运行，\n$ npm build # 只是代码的工程化工具`,
        "PHP": `\\\\ 作为最好的语言，当然不需要编译`,
        "Go": `$ go build ./main.go`,
    },
    run: {
        "JavaScript": `\\\\ 浏览器环境，打开控制台输入代码就能运行\n\\\\ node index.js`,
        "PHP": `$ php index.php`,
        "Go": `$ go run ./main.go `,
    },
    stdio: {
        "JavaScript": `// 输出
console.log("Hello, world!");`,
        "PHP": `// 输出
echo "Hello~";
print("Hello");
printf("Age is %d", 18);
// 命令行输入
var_dump($argv); // $argv 包含了所有命令行参数
// Script example.php --a=12 --b=abc execSql
$rest_index = null;
$opts = getopt('a:b:', [], $rest_index);
`,
        "Go": `// 输出
import("fmt")

fmt.Print("Haha\n")
fmt.Println("HaHa")
fmt.Printf("Age is %d", 18)
// 命令行输入
import (
"bufio"
"fmt"
"os"
)
// 创建一个map 指定key为string类型 val为int类型
counts := make(map[string]int)
// 从标准输入流中接收输入数据
input := bufio.NewScanner(os.Stdin)
for input.Scan() {
line := input.Text()
// 输入空时 结束
if line == "" {
break
}
// 更新key对应的val 新key对应的val是默认0值
counts[line]++
}
`,
    },
    comment: {
        "JavaScript": `// 这是一行注释
document.getElementById("myP").innerHTML = "我的第一个段落。"; 
/*
console.log("不会被运行的多行注释");
*/`,
        "PHP": `/* 这是多行注释 */
function cons(string $time = "now", DateTimeZone $timezone = null) {}
/* 属性 */
$y = 2.1;`,
        "Go": `func main() {
            /* 这是我的第一个简单的程序的多行注释，虽然只写了一行 */
            fmt.Println("Hello, World!")
            // 这又是一条注释
            fmt.Println("Hello, itjsz.com")
         }`,
    },
    pkg: {
        "JavaScript": `
// 包管理方案较多，官方推荐npm，额外还有cpmn,pnpm,yarn 等
## 自己初始化一个包
$ npm init 

## 在当前项目安装一个依赖性
$ npm install <Module Name>

## 全局安装
$ npm install npm -g

## 安装 dev 依赖
$ npm install webpack-dev-server --save-dev
$ yarn add -D webpack-dev-server

## 更新包到指定版本
$ npm update react@17.0.1
$ yarn upgrade react@17.0.1

## 展示当前项目已安装依赖
$ npm list

## 卸载某个包
$ npm uninstall express

## 搜索相关 包
$ npm search express
`,
        "PHP": `// php 就一个 composer 包管理，社区做的。安装它
$ curl -sS https://getcomposer.org/installer | php
// 初始化
$ composer init

// 安装
$ composer install laravel

// 更新
$ composer update

// 搜索
$ composer search yii2

// 展示安装的包
$ composer show --installed`,
        "Go": `// Go的包管理由官方实现，这里展示的是 1.13 版本后的包管理。
// 初始化
$ go mod init

// 安装包 gin
$ go get github.com/gin-gonic/gin
// go version >= 1.17 
$ go install github.com/gin-gonic/gin

// 展示所有依赖
$ go mod graph

// 展示当前包名
$ go list

// 更新
$ go mod tidy`,
    },
    keywords: {
        "JavaScript": `
| Keywords ECMAScript 2015 | Future reserved keywords | Future reserved keywords in older standards | Identifiers with special meanings |
| ------------------------ | ------------------------ | ------------------------------------------- | --------------------------------- |
| case                     | enum                     | abstract                                    | arguments                         |
| catch                    |                          | boolean                                     | get                               |
| class                    | implements               | byte                                        | set                               |
| const                    | interface                | char                                        |                                   |
| continue                 | let                      | double                                      |                                   |
| debugger                 | package                  | final                                       |                                   |
| default                  | private                  | float                                       |                                   |
| delete                   | protected                | goto                                        |                                   |
| do                       | public                   | int                                         |                                   |
| else                     | static                   | long                                        |                                   |
| export                   | yield                    | native                                      |                                   |
| extends                  |                          | short                                       |                                   |
| finally                  | await                    | synchronized                                |                                   |
| for                      |                          | throws                                      |                                   |
| function                 |                          | transient                                   |                                   |
| if                       |                          | volatile                                    |                                   |
| import                   |                          |                                             |                                   |
| in                       |                          |                                             |                                   |
| instanceof               |                          |                                             |                                   |
| new                      |                          |                                             |                                   |
| return                   |                          |                                             |                                   |
| super                    |                          |                                             |                                   |
| switch                   |                          |                                             |                                   |
| this                     |                          |                                             |                                   |
| throw                    |                          |                                             |                                   |
| try                      |                          |                                             |                                   |
| typeof                   |                          |                                             |                                   |
| var                      |                          |                                             |                                   |
| void                     |                          |                                             |                                   |
| while                    |                          |                                             |                                   |
| with                     |                          |                                             |                                   |
| yield                    |                          |                                             |                                   |
参考： https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Lexical_grammar`,
        "PHP": `
| __halt_compiler() | abstract       | and | array()  | as |
| ----------------- | -------------- | -------------- | -------------- | -------------- |
| break | callable (as of PHP 5.4) | case | catch | class |
| clone | const | continue | declare | default |
| die()      | do | echo      | else | elseif |
| empty()  | enddeclare | endfor | endforeach | endif |
| endswitch | endwhile | eval()    | exit()    | extends |
| final | finally (从PHP 5.5开始) | for | foreach | function |
| global | goto (从PHP 5.3开始) | if | implements | include |
| include_once | instanceof | insteadof (从PHP 5.4开始) | interface | isset()  |
| list()    | namespace (从PHP 5.3开始) | new | or | print    |
| private | protected | public | require | require_once |
| return  | static | switch | throw | trait (从PHP 5.4开始) |
| try | unset()  | use | var | while |
| xor | yield (从PHP 5.5开始) |                                                              |                                                              |                                                              |

| __CLASS__      | __DIR__ (从PHP 5.3开始) | __FILE__ | __FUNCTION__ | __LINE__ | __METHOD__ |
| -------------- | -------------- | -------------- | -------------- | -------------- | -------------- |
| __NAMESPACE__ (从PHP 5.3开始) | __TRAIT__ (从PHP 5.4开始) |                                                              |                                                              |                                                              |                                                              |

|   | | | |
| ------------------ | ------------------- | ------------------ | -------------------- |
| int (从PHP 7开始)  | float (从PHP 7开始) | bool (从PHP 7开始) | string (从PHP 7开始) |
| true (从PHP 7开始) | false (从PHP 7开始) | null (从PHP 7开始) |                      |

|                        |                      |                     |                       |
| ---------------------- | -------------------- | ------------------- | --------------------- |
| resource (从PHP 7开始) | object (从PHP 7开始) | mixed (从PHP 7开始) | numeric (从PHP 7开始) |

参考： https://www.php.net/manual/zh/reserved.keywords.php`,
        "Go": `break        default      func         interface    select
case         defer        go           map          struct
chan         else         goto         package      switch
const        fallthrough  if           range        type
continue     for          import       return       var

参考： https://golang.org/ref/spec`,
    },
    naming_rules: {
        "JavaScript": `\\\\ 1. 首字母必须是字母、下划线（-）或者美元符号（$）\n\\\\ 2. 其他字母可以是下划线（_）、美元符号（$）、字母或者数字\n\\\\ 3.变量名是区分大小写的，不能是关键字或保留字`,
        "PHP": `//// 1. 变量以美元符号$开头 \n\\\\ 2. 美元符号$后面的第一个字符不可以是数字，只能是下划线_或者字母。\n\\\\ 3. 除了下划线_外，变量不允许出现任何空格或标点符号。也就是说变量名只能包含：a-z、A-Z、0-9 以及下划线_ \n\\\\ 4. PHP变量名是区分大小写的。如$name与$Name是两个不同的变量。`,
        "Go": `var a = 1\nb := "abc"\nstr string = "hello!"`,
    },
    state: {
        "JavaScript": `var a = 1;\nlet b = 'abc'\nstr = 'hello!';`,
        "PHP": `$a = 1;\n$b = 'abc';\n$str = 'hello!';`,
        "Go": `var a = 1\nb := "abc"\nstr string = "hello!"`,
    },
    int: {
        "JavaScript": `// Javascript 只有粗略的 Number概率，没有区别 int，float
var a = 1;
let b = parseInt("2")
c = Math.round(10);`,
        "PHP": `// php本来是若类型的，在php7之后可以指定变量类型
$a = 1;
$a = 0123; // 八进制数 (等于十进制 83)
$a = 0x1A; // 十六进制数 (等于十进制 26)
$a = 0b11111111; // 二进制数字 (等于十进制 255)
$a = 1_234_567; // 整型数值 (PHP 7.4.0 以后)
$b = int(12);`,
        "Go": `// golang 整型细分有： int,int32,int64,uint,uint32,uint32
var a int = 1
b := 12
var c int32 = -12
c, _ := strconv.Atoi("123")`,
    },
    float: {
        "JavaScript": `var a = 1;\nlet b = 'abc'\nstr = 'hello!';`,
        "PHP": `$a = 1;\n$b = 'abc';\n$str = 'hello!';`,
        "Go": `var a = 1\nb := "abc"\nstr string = "hello!"`,
    },
    bool: {
        "JavaScript": `var str = "string"\nlet str2 = String("haha");`,
        "PHP": `$a = 12;\n$b = 'c';\n$d=12.5`,
        "Go": `package main

import (
            "fmt"
            "github.com/PaulXu-cn/goeval"
        )
        
        func main() {
            if re, err := goeval.Eval(
                "",
                "fmt.Print(\"Hello World! itjsz.com\")",
                "fmt"); nil == err {
                fmt.Print(string(re))
            } else {
                fmt.Print(err.Error())
            }
        }`,
    },
    char: {
        "Go": `package main

        import (
            "fmt"
            "github.com/PaulXu-cn/goeval"
        )
        
        func main() {
            if re, err := goeval.Eval(
        "",
        "fmt.Print(\"Hello World! itjsz.com\")",
        "fmt"); nil == err {
        fmt.Print(string(re))
        } else {
        fmt.Print(err.Error())
        }
        }
        `
    },
    string: {
        "JavaScript": `\\\\ 浏览器环境，打开控制台输入代码就能运行\n\\\\ node index.js`,
        "PHP": `$ php index.php`,
        "Go": `$ go run ./main.go `,
    },
    assignment: {
        "JavaScript": `\\\\ 浏览器环境，打开控制台输入代码就能运行\n\\\\ node index.js`,
        "PHP": `$ php index.php`,
        "Go": `$ go run ./main.go `,
    },
    const: {
        "JavaScript": `\\\\ 浏览器环境，打开控制台输入代码就能运行\n\\\\ node index.js`,
        "PHP": `$ php index.php`,
        "Go": `$ go run ./main.go `,
    },
    enum: {
        "JavaScript": `\\\\ 浏览器环境，打开控制台输入代码就能运行\n\\\\ node index.js`,
        "PHP": `$ php index.php`,
        "Go": `$ go run ./main.go `,
    },
    macro: {
        "JavaScript": `\\\\ 浏览器环境，打开控制台输入代码就能运行\n\\\\ node index.js`,
        "PHP": `$ php index.php`,
        "Go": `$ go run ./main.go `,
    },
    print: {
        "JavaScript": `\\\\ 浏览器环境，打开控制台输入代码就能运行\n\\\\ node index.js`,
        "PHP": `$ php index.php`,
        "Go": `$ go run ./main.go `,
    },
    arithmentic: {
        "JavaScript": `\\\\ 浏览器环境，打开控制台输入代码就能运行\n\\\\ node index.js`,
        "PHP": `$ php index.php`,
        "Go": `$ go run ./main.go `,
    },
    condition: {
        "JavaScript": `\\\\ 浏览器环境，打开控制台输入代码就能运行\n\\\\ node index.js`,
        "PHP": `$ php index.php`,
        "Go": `$ go run ./main.go `,
    },
    bit: {
        "JavaScript": `\\\\ 浏览器环境，打开控制台输入代码就能运行\n\\\\ node index.js`,
        "PHP": `$ php index.php`,
        "Go": `$ go run ./main.go `,
    },
    conditional: {
        "JavaScript": `\\\\ 浏览器环境，打开控制台输入代码就能运行\n\\\\ node index.js`,
        "PHP": `$ php index.php`,
        "Go": `$ go run ./main.go `,
    },
    recursion_for: {
        "JavaScript":
            `\\\\ 浏览器环境，打开控制台输入代码就能运行\n\\\\ let arr = ['a', '2', 3, '$', '555'];
// for i++
for (let i = 0; i< arr.length; i++) {
    console.log('arr for i=', i, ' val=', arr[i]);
}
`,
        "PHP": `$ php index.php`,
        "Go": `$for i := 0; i < 10; i++ {
    fmt.Printf("for i++: i = %d\n", i)
}`,
    },
    recursion_foreach: {
        "JavaScript":
            `let arr = ['a', '2', 3, '$', '555'];
// for of array
for (let val of arr) {
    console.log('arr for-of val=', val);
}
var student={name:'Lee',sex:'man',age:12}; 
// for of ele
for (let val of arr) {
    console.log('arr for-of val=', val);
}
// for of k-v
for (let [key, value] of Object.entries(student)) {
    console.log(\`obj for k-v: \${key} => \${value}\`);
}
// for in attr
for (let attr in student)
{
    console.log('sudent infor for-in attr=', attr, ' val=', student[attr]);
}`,
        "PHP": `$ php index.php`,
        "Go": `var theMap = map[string]uint32{"a": 1, "b": 2, "c": 4}
for key, value := range theMap {
    fmt.Printf("for range k = %s v = %d\n", key, value)
}
`,
    },
    recursion_while: {
        "JavaScript":
            `let i = 0;
while (i<10)
{
    console.log('while i=', i);
    i++;
}`,
        "PHP": `$ php index.php`,
        "Go": `var i = 0
for i < 10 {
    fmt.Printf("for-while, i = %d\n", i)
    i++
}`,
    },
    obj_attr: {
        "JavaScript":
            `var student={name:'Lee',sex:'man',age:12}; 
for (let [key, value] of Object.entries(student)) {
    console.log(\`obj for k-v: \${key} => \${value}\`);
}
`,
        "PHP": `$ php index.php`,
        "Go": ``,
    },
    error: {
        "JavaScript": `\\\\ 浏览器环境，打开控制台输入代码就能运行\n\\\\ node index.js`,
        "PHP": `$ php index.php`,
        "Go": `$ go run ./main.go `,
    }
}

class SyntaxMainContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lang: 'go',
            theme: 'light',
            lastChange: '',
            currentMenuKey: "introduce",
            checkedList: ['JavaScript', 'PHP', 'Go'],
            plainOptions: ['C/C++', 'Java', 'Python', 'C#', 'JavaScript', 'PHP', 'Go', 'Swift', "Objective-C", 'Rust', 'Dart', 'Lua', 'Typescript', 'Bash'],
            mapping: {
                'JavaScript': "javascript",
                'C/C++': "c++",
                'Java': "java",
                'Python': "python",
                'C#': "csharp",
                'PHP': 'php',
                'Go': "go",
                'Swift': "swift",
                "Rust": "rust"
            },
            openKeys: ['basic'],
            rootSubmenuKeys: ['basic', 'variables_and_types', 'expression_and_operator', 
            'control_flow_and_error_handing', 'container', 'sub3'],
            codes: theCodes
        }

        this.onCheckListChange = this.onCheckListChange.bind(this);
        this.onSubMenuClick = this.onSubMenuClick.bind(this);
    }

    onOpenChange = (openKeys) => {
        console.log('content syntax stat', this.state);
        const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
        if (this.state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
            this.setState({ openKeys });
        } else {
            this.setState({
                openKeys: latestOpenKey ? [latestOpenKey] : [],
            });
        }
    }

    onCheckListChange(list) {
        this.setState({ checkedList: list });
    }

    onSubMenuClick(e) {
        this.setState({ currentMenuKey: e.key })
    }

    render() {
        return (
            <Layout className="site-layout-background">
                {/* <Layout className="site-layout-background" style={{ padding: '24px 0' }}> */}
                <Sider className="site-layout-background" width={200}>
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={['introduce']}
                        defaultOpenKeys={['basic']}
                        openKeys={this.state.openKeys}
                        onOpenChange={this.onOpenChange}
                        style={{ height: '100%' }}
                    >
                        <SubMenu key="basic" icon={<BarsOutlined />} title="基本概率">
                            <Menu.Item key="introduce" onClick={this.onSubMenuClick}>介绍</Menu.Item>
                            <Menu.Item key="install" onClick={this.onSubMenuClick}>安装</Menu.Item>
                            <Menu.Item key="hello_world" onClick={this.onSubMenuClick}>Hello World</Menu.Item>
                            <Menu.Item key="complie" onClick={this.onSubMenuClick}>编译</Menu.Item>
                            <Menu.Item key="run" onClick={this.onSubMenuClick}>运行</Menu.Item>
                            <Menu.Item key="stdio" onClick={this.onSubMenuClick}>输出输出</Menu.Item>
                            <Menu.Item key="comment" onClick={this.onSubMenuClick}>注释</Menu.Item>
                            <Menu.Item key="pkg" onClick={this.onSubMenuClick}>包管理</Menu.Item>
                        </SubMenu>
                        <SubMenu key="variables_and_types" icon={<BuildOutlined />} title="变量与类型">
                            <Menu.Item key="keywords" onClick={this.onSubMenuClick}>关键词</Menu.Item>
                            <Menu.Item key="naming_rules" onClick={this.onSubMenuClick}>命名规则</Menu.Item>
                            <Menu.Item key="state" onClick={this.onSubMenuClick}>声明</Menu.Item>
                            <Menu.Item key="int" onClick={this.onSubMenuClick}>整型</Menu.Item>
                            <Menu.Item key="float" onClick={this.onSubMenuClick}>浮点型</Menu.Item>
                            <Menu.Item key="bool" onClick={this.onSubMenuClick}>布尔</Menu.Item>
                            <Menu.Item key="char" onClick={this.onSubMenuClick}>字符</Menu.Item>
                            <Menu.Item key="string" onClick={this.onSubMenuClick}>字符串</Menu.Item>
                            <Menu.Item key="assignment" onClick={this.onSubMenuClick}>赋值</Menu.Item>
                            <Menu.Item key="const" onClick={this.onSubMenuClick}>常量</Menu.Item>
                            <Menu.Item key="enum" onClick={this.onSubMenuClick}>枚举</Menu.Item>
                            <Menu.Item key="macro" onClick={this.onSubMenuClick}>宏</Menu.Item>
                            <Menu.Item key="print" onClick={this.onSubMenuClick}>打印</Menu.Item>
                        </SubMenu>
                        <SubMenu key="expression_and_operator" icon={<CalculatorOutlined />} title="表达式与操作符">
                            <Menu.Item key="arithmentic" onClick={this.onSubMenuClick}>算术运算</Menu.Item>
                            <Menu.Item key="condition" onClick={this.onSubMenuClick}>条件</Menu.Item>
                            <Menu.Item key="bit" onClick={this.onSubMenuClick}>位运算</Menu.Item>
                        </SubMenu>
                        <SubMenu key="control_flow_and_error_handing" icon={<ApartmentOutlined />} title="流程控制与错误处理">
                            <Menu.Item key="conditional" onClick={this.onSubMenuClick}>条件判断</Menu.Item>
                            <SubMenu key="recursion" icon={<ApartmentOutlined />} title="循环">
                                <Menu.Item key="recursion_for" onClick={this.onSubMenuClick}>for</Menu.Item>
                                <Menu.Item key="recursion_foreach" onClick={this.onSubMenuClick}>foreach</Menu.Item>
                                <Menu.Item key="recursion_while" onClick={this.onSubMenuClick}>while/loop</Menu.Item>
                            </SubMenu>
                            <Menu.Item key="error" onClick={this.onSubMenuClick}>错误处理</Menu.Item>
                        </SubMenu>
                        <SubMenu key="container" icon={<ContainerOutlined />} title="容器">
                            <Menu.Item key="list" onClick={this.onSubMenuClick}>列表</Menu.Item>
                            <Menu.Item key="dict" onClick={this.onSubMenuClick}>字典</Menu.Item>
                            <Menu.Item key="k-v" onClick={this.onSubMenuClick}>K-V</Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub3" icon={<LineChartOutlined />} title="函数">
                            <Menu.Item key="31" onClick={this.onSubMenuClick}>函数声明与调用</Menu.Item>
                            <Menu.Item key="32" onClick={this.onSubMenuClick}>闭包</Menu.Item>
                            <Menu.Item key="33" onClick={this.onSubMenuClick}>匿名函数</Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub4" icon={<DiffOutlined />} title="结构体与对象">
                            <Menu.Item key="41">定义</Menu.Item>
                            <Menu.Item key="42">创建</Menu.Item>
                            <Menu.Item key="obj_attr">属性</Menu.Item>
                        </SubMenu>
                        <SubMenu key="sub5" icon={<BlockOutlined />} title="接口与抽象">
                            <Menu.Item key="51">接口定义</Menu.Item>
                            <Menu.Item key="52">抽象类定义</Menu.Item>
                            <Menu.Item key="53">实现</Menu.Item>
                        </SubMenu>
                    </Menu>
                </Sider>

                <Content style={{ padding: '0 24px', minHeight: 280 }}>
                    <CheckLangBar checkedList={this.state.checkedList} plainOptions={this.state.plainOptions}
                        onChange={this.onCheckListChange}></CheckLangBar>
                    <ShowCodes checkedList={this.state.checkedList} codes={this.state.codes}
                        menuKey={this.state.currentMenuKey} langMap={this.state.mapping}></ShowCodes>
                </Content>

            </Layout >
        )
    }
}

export default SyntaxMainContent;