import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'antd';

const CheckboxGroup = Checkbox.Group;

const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 20,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 1,
        span: 20,
    },
};

class CheckLangBar extends React.Component {
    static propTypes = {
        checkedList: PropTypes.array,
        plainOptions: PropTypes.array,
    }

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Form {...layout} name="basic" layout="inline" onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed} style={{ margin: '10px 10px 10px 5px' }}>

                <Form.Item label="对比代码" name="syntax" valuePropName="checked">
                    <CheckboxGroup options={this.props.plainOptions} value={this.props.checkedList} onChange={this.props.onChange} />
                </Form.Item>

            </Form>
        )
    }
}

export default CheckLangBar;