
import React from 'react';
import { Alert } from 'antd';
import PropTypes from 'prop-types';

// 错误弹框
class AlertBar extends React.Component {
    static propTypes = {
        alterType: PropTypes.string,
        title: PropTypes.string,
        msg: PropTypes.string,
        closeCallBack: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.onClose = this.onClose.bind(this);
    }

    onClose(e) {
        this.props.closeCallBack();
    }

    render() {
        const errBarStyle = {
            margin: '10px 30px',
            zIndex: 100,
            float: 'right',
            right: '10px',
            position: 'fixed',
            top: '72px',
        }

        if (undefined == this.props.msg || 1 > this.props.msg.length) {
            return (
                <>
                </>
            )
        } else {
            return (
                <Alert span={20} style={errBarStyle}
                message="Error"
                description={this.props.msg}
                type="error" onClose={this.onClose}
                showIcon closable
            />
            )
        }
    }
}

export default AlertBar;