import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Checkbox } from 'antd';
import {LoadingOutlined } from '@ant-design/icons';

const CheckboxGroup = Checkbox.Group;

const layout = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 20,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 1,
    span: 20,
  },
};

class OptionBar extends React.Component {
  static propTypes = {
    checkedList: PropTypes.array,
    plainOptions: PropTypes.array,
    onDo: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  render() {
    let button = null;
    if (this.props.loading) {
      button = <Button type="primary" htmlType="button" onClick={this.props.onDo}>提交<LoadingOutlined /></Button> 
    } else {
      button = <Button type="primary" htmlType="button" onClick={this.props.onDo}>提交</Button>
    }
    return (
      <Form {...layout} name="basic" layout="inline" onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed} style={{ margin: '10px 30px' }}>

        <Form.Item label="需要生成的代码" name="syntax" valuePropName="checked">
          <CheckboxGroup options={this.props.plainOptions} value={this.props.checkedList} onChange={this.props.onChange} />
        </Form.Item>

        <Form.Item >
          {button}
        </Form.Item>
      </Form>
    )
  }
}

export default OptionBar;