import React from 'react';
import PropTypes from 'prop-types';
import { For } from 'react-loops'
// import CodeInput from './CodeInput';
import CodeInput2 from './CodeInput2';
import './../css/style.css';

class CodeInputs extends React.Component {
    static propTypes = {
        checkedList: PropTypes.array,
        mapping: PropTypes.object,
        current: PropTypes.string,
        onCodeChange: PropTypes.func.isRequired,
        onCodeLastChange: PropTypes.func.isRequired,
    }

    constructor (props) {
        super(props);
        this.state = {}
    }

    render () {
        return (
            <div className="space-align-container">
            <For of={this.props.checkedList} 
            as={item => <CodeInput2 syntax = {item} mapping = {this.props.mapping} options={this.props.options} 
            current={this.props.current} onCodeChange={this.props.onCodeChange} onCodeLastChange={this.props.onCodeLastChange}/>} />
            </div>
            )
    }
}

export default CodeInputs;