import React, { useState } from 'react';
import { Row, Col, Alert, message } from 'antd';
import CurlForm from './CurlForm';
import CurlFormFunc from './CurlFormFunc';
import { parseCurlCommandLine, MyLogger } from '../lib/curl2json';
// import curlct from 'curlconverter';

var Config = require('Config')
// var curlconverter = require('curlconverter');
class CurlToLocal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            remoteLoading: false,
            localLoading: false,

            remoteAuto: false,
            localAuto: false,

            from: {
                schema: "https",
                hostName: "",
                port: "",
                path: "",
                method: "",
                dataType: "",
                referer: "",
                origin: "",
                curl: "",
            },
            to: {
                schema: "http",
                hostName: "127.0.0.1",
                port: "80",
                path: "",
                method: "",
                dataType: "",
                referer: "",
                origin: "",
                curl: "",
            }
        }

        this.onToLocalCurl = this.onToLocalCurl.bind(this);
        this.onToRemoteCurl = this.onToRemoteCurl.bind(this);

        this.onLocalChange = this.onLocalChange.bind(this);
        this.onRemoteChange = this.onRemoteChange.bind(this);

        this.switchRemoteAutoSubmit = this.switchRemoteAutoSubmit.bind(this);
        this.switchLocalAutoSubmit = this.switchLocalAutoSubmit.bind(this);
    }

    callApi = async function (that, data) {
        // that.setState({ remoteLoading: true });
        try {
            // ⛔️ TypeError: Failed to fetch
            // 👇️ incorrect or incomplete URL
            const response = await fetch(Config.serverUrl + '/curl2local', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: data
            });

            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }

            const result = await response.json();
            return result;
        } catch (err) {
            console.log(err);
        }
    }

    onToLocalCurl(remoteUrl) {
        let st = this.state;
        st.loading = true;
        st.remoteLoading = true;
        this.setState(st);

        let theCurl = this.state.from.curl.slice();
        let newBuff = Buffer.from(theCurl, 'utf-8');
        let originData = { "from": this.state.from, "to": this.state.to }
        originData.from.curl = newBuff;
        originData.from.curl = newBuff.toString('base64');
        let dataJson = JSON.stringify(originData);

        this.callApi(this, dataJson).then((result) => {
            if (undefined === result) {
                return
            }
            if (result.code != 200) {
                this.newAlert(result.msg);
                return console.log("request return err code " + result.code)
            }
            let newState = this.state;
            let data = result.data
            if (undefined !== data.to.curl) {
                let newCurl = Buffer.from(data.to.curl, 'base64').toString('utf-8');
                newState.to.curl = newCurl;
                newState.from.curl = theCurl;
                this.setState(newState);
            }
        }).then((st = this.state, th = this) => {
            st.loading = false;
            st.remoteLoading = false;
            // st.localLoading = false;
            th.setState(st);
        })
    }

    // 转换本地 curl 到远端 curl
    onToRemoteCurl(localUrl) {
        let st = this.state;
        st.loading = true;
        st.localLoading = true;
        this.setState(st);

        let theCurl = this.state.to.curl.slice();
        let newBuff = Buffer.from(theCurl, 'utf-8');
        let originData = { "from": this.state.to, "to": this.state.from }
        originData.from.curl = newBuff.toString('base64');
        let dataJson = JSON.stringify(originData);

        this.callApi(this, dataJson).then((result) => {
            if (undefined === result) {
                return
            }
            if (result.code != 200) {
                this.newAlert(result.msg);
                return console.log("request return err code " + result.code)
            }
            let newState = this.state;
            let data = result.data
            if (undefined !== data.to.curl) {
                let newCurl = Buffer.from(data.to.curl, 'base64').toString('utf-8');
                newState.from.curl = newCurl;
                newState.to.curl = theCurl;
                this.setState(newState);
            }
        }).then((st = this.state, th = this) => {
            st.loading = false;
            st.localLoading = false;
            // st.localLoading = false;
            th.setState(st);
        })
    }

    onRemoteChange(e) {
        let newState = this.state;
        Object.entries(e).forEach(([key, val]) => {
            if (undefined === val || '' == val || !(key in newState.from)) {
                return
            }
            if ("string" === typeof(val) || undefined === val.target) {
                // 如果是字符串，或者非 event target 则跳出
                return
            } else if ('curl' == key && undefined != val.target) {
                if (val.target.value.length < 0) {
                    newState.from.curl = val.target.value;
                } else {
                    let re = {}
                    try {
                        re = parseCurlCommandLine(val.target.value, new MyLogger);
                        re.paraseDetail();
                    } catch (err) {
                        message.error('输入 cURL 有误', 3);
                        console.log(err);
                        newState.from.curl = val.target.value;
                        return
                    }
                    newState.from.hostName = re.hostName;
                    newState.from.schema = re.protocol;
                    newState.from.port = re.port;
                    newState.from.path = re.path;
                    newState.from.method = re.method;
                    // newState.from.dataType = re.dataType;
                    newState.from.origin = re.origin;
                    newState.from.curl = val.target.value;
                    if (re.urlObj != undefined) {
                        if (re.urlObj.search.length > 0) {
                            newState.from.dataType = "query_str"
                        }
                        if (undefined != re.body && re.body.length > 0) {
                            newState.from.dataType = "json"
                        }
                    }
                }
            } else if (undefined !== val.target) {
                newState.from[key] = val.target.value;
            }
        });
        this.setState(newState);
    }

    onLocalChange(e) {
        let newState = this.state;
        Object.entries(e).forEach(([key, val]) => {
            if (undefined === val || '' == val || !(key in newState.to)) {
                return
            }
            if ("string" === typeof(val) || undefined === val.target) {
                // 如果是字符串，或者非 event target 则跳出
                return
            } else if ('curl' == key && undefined != val.target) {
                if (val.target.value.length < 0) {
                    newState.to.curl = val.target.value;
                } else {
                    let re = {}
                    try {
                        re = parseCurlCommandLine(val.target.value, new MyLogger);
                        re.paraseDetail();
                    } catch (err) {
                        message.error('输入 cURL 有误', 3);
                        console.log(err);
                        newState.from.curl = val.target.value;
                        return
                    }

                    newState.to.hostName = re.hostName;
                    newState.to.schema = re.protocol;
                    newState.to.port = re.port;
                    newState.to.path = re.path;
                    newState.to.method = re.method;
                    // newState.to.dataType = re.dataType;
                    newState.to.origin = re.origin;
                    newState.to.curl = val.target.value;
                    if (re.urlObj != undefined) {
                        if (re.urlObj.search.length > 0) {
                            newState.from.dataType = "query_str"
                        }
                        if (undefined != re.body && re.body.length > 0) {
                            newState.from.dataType = "json"
                        }
                    }
                }
            } else if (undefined !== val.target) {
                newState.to[key] = val.target.value;
            }
        });
        this.setState(newState);
    }

    switchRemoteAutoSubmit(checked, e) {
        let newState = this.state;
        newState.remoteAuto = checked;
        this.setState(newState);
    }

    switchLocalAutoSubmit(checked, e) {
        let newState = this.state;
        newState.remoteAuto = checked;
        this.setState(newState);
    }

    render() {
        let sty = { margin: "20px 10px" }
        return (
            <div>
                <Row>
                    <Col span={11} style={sty}>
                        <CurlFormFunc name='normal_login' title='远端cURL' btn='转换cURL->' value={this.state.from}
                            loading={this.state.remoteLoading} onTransfer={this.onToLocalCurl} onChange={this.onRemoteChange}
                            auto={this.state.remoteAuto} switchAutoSubmit={this.switchRemoteAutoSubmit} />
                    </Col>
                    <Col span={11} style={sty} >
                        <CurlFormFunc name='normal_login' title='本地用cURL' btn='<-转换cURL' value={this.state.to}
                            loading={this.state.localLoading} onTransfer={this.onToRemoteCurl} onChange={this.onLocalChange}
                            auto={this.state.localAuto} switchAutoSubmit={this.switchLocalAutoSubmit} />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default CurlToLocal;