import React from 'react';
import { Card } from 'antd';
import PropTypes from 'prop-types'

import CodeMirror from '@uiw/react-codemirror';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/idea.css';
// import 'codemirror/theme/monokai.css';
import "codemirror/lib/codemirror.css";
import 'codemirror/theme/eclipse.css';

class ShowCode extends React.Component {
    static propTypes = {
        lang: PropTypes.string,
        code: PropTypes.string
    }

    constructor(props) {
        super(props);

        let theCode = '';
        if ("string" == typeof (this.props.children)) {
            theCode = this.props.children ? this.props.children.replace(/^\s+|\s+$/g, '') : this.props.children;
        }
        this.state = { code: theCode };
    }

    render() {
        let theCode = this.props.code ? this.props.code.replace(/^\s+|\s+$/g, '') : this.props.code;

        // return (<Card size='small' title={this.props.lang}><SyntaxHighlighter
        //     showLineNumbers={true} startingLineNumber={1} language={this.props.lang}
        //     style={docco} lineNumberStyle={{ color: '#ddd', fontSize: 15 }}
        //     wrapLines={true}>{theCode}</SyntaxHighlighter></Card>)
        let lang = this.props.lang;

        return (
            <Card size='small' title={this.props.lang}><CodeMirror
                value={theCode}
                options={{
                    keyMap: 'sublime',
                    mode: lang,
                    theme: 'idea',
                    tabSize: 2,
                    readOnly: true,
                }}
            /></Card>)
    }
}

export default ShowCode;