import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Switch, Space } from 'antd';
import { LoadingOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';

const { Option } = Select;
const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 4,
        span: 20,
    },
};

const CurlFormFunc = (props) => {
    const [form] = Form.useForm();

    form.setFieldsValue({
        schema: props.value.schema,
        hostName: props.value.hostName,
        port: props.value.port,
        path: props.value.path,
        // data_type: props.value.data_type,
        referer: props.value.referer,
        origin: props.value.origin,
        curl: props.value.curl,
    });

    if (undefined != props.value.dataType && props.value.dataType.length > 0) {
        form.setFieldsValue({
            dataType: props.value.dataType,
        });
    }

    if (undefined != props.value.method && props.value.method.length > 0) {
        form.setFieldsValue({
            method: props.value.method,
        });
    }

    const handleSchemaChange = (schema) => {
        triggerChange({ schema });
    };

    const handleHostNameChange = hostName => {
        triggerChange({ hostName });
    };

    const handlePortChange = port => {
        triggerChange({ port });
    };

    const handlePathChange = path => {
        triggerChange({ path });
    };

    const handleDataTypeChange = dataType => {
        triggerChange({ dataType });
    };

    const handleMethodChange = method => {
        triggerChange({ method });
    };

    const handleOriginChange = origin => {
        triggerChange({ origin });
    };

    const handleRefererChange = referer => {
        triggerChange({ referer });
    };

    const handleCurlChange = curl => {
        triggerChange({ curl });
    };

    const triggerChange = (changedValue) => {
        const { onChange, value } = props;
        if (onChange) {
            onChange({
                ...value,
                ...changedValue,
            });
        }
    };

    const onFinish = (values) => {
        console.log(values);
    };

    const onReset = () => {
        form.resetFields();
    };

    const formTitle = (undefined != props.title) ? props.title : '转换cURL';
    const titleStyle = { textAlign: 'center' }
    const btnStyle = { margin: "0px 40px" }

    let button = <Button type="primary" style={btnStyle} onClick={props.onTransfer}>{props.btn}<LoadingOutlined /></Button>
    if (props.loading) {
    } else {
        button = <Button type="primary" style={btnStyle} onClick={props.onTransfer}>{props.btn}</Button>
    }

    return (
        <>
            <h2 style={titleStyle}>{formTitle}</h2>
            <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>

                <Form.Item
                    label="Schema"
                    name="schema"
                    rules={[
                        { required: false, message: 'Please input Http Schema' }
                    ]}>
                    <Input placeholder='Please input Http Schema' onChange={handleSchemaChange} />
                </Form.Item>

                <Form.Item
                    label="HostName"
                    name="hostName"
                    rules={[
                        { required: false, message: 'Please input Http Hostname, like: www.itjsz.com' }
                    ]}>
                    <Input placeholder='Please input Http Hostname' onChange={handleHostNameChange} />
                </Form.Item>

                <Form.Item
                    label="Port"
                    name="port"
                    rules={[
                        { required: false, message: 'Please input Http Port' }
                    ]}>
                    <Input placeholder='Please input Http Port' onChange={handlePortChange} />
                </Form.Item>

                <Form.Item
                    label="Path"
                    name="path"
                    rules={[
                        { required: false, message: 'Please input Http Path' }
                    ]}>
                    <Input placeholder='Please input Http Path' onChange={handlePathChange} />
                </Form.Item>

                <Form.Item label="Method" name="method_data_type">
                    <Input.Group compact>
                        <Form.Item name="method">
                            <Select placeholder='Select Method' style={{ width: 140 }} onChange={handleMethodChange}>
                                <Option value="GET">GET</Option>
                                <Option value="POST">POST</Option>
                                <Option value="PUT">PUT</Option>
                                <Option value="DELETE">DELETE</Option>
                                <Option value="OPTIONS">OPTIONS</Option>
                                <Option value="HEAD">HEAD</Option>
                                <Option value="CONNECT">CONNECT</Option>
                                <Option value="TRACE">TRACE</Option>
                                <Option value="PATCH">PATCH</Option>

                            </Select>
                        </Form.Item>

                        <Form.Item name="dataType" >
                            <Select placeholder='Select Data Type' style={{ width: 160 }} onChange={handleDataTypeChange}>
                                <Option value="json">JSON</Option>
                                <Option value="query_str">Query String</Option>
                                <Option value="form_data">Form Data</Option>
                            </Select>
                        </Form.Item>
                    </Input.Group>
                </Form.Item>

                <Form.Item
                    label="Origin"
                    name="origin"
                    rules={[
                        { required: false, message: 'Please input Http Header Origin' }
                    ]}>
                    <Input placeholder='Please input Http Header Origin' onChange={handleOriginChange} />
                </Form.Item>

                <Form.Item
                    label="Referer"
                    name="referer"
                    rules={[
                        { required: false, message: 'Please input Http Header Referer' }
                    ]}>
                    <Input placeholder='Please input Http Header Referer' onChange={handleRefererChange} />
                </Form.Item>

                <Form.Item
                    label="cURL"
                    name="curl"
                    rules={[{ required: true, message: 'Please paste cURL!' }]}
                >
                    <Input.TextArea
                        showCount
                        maxLength={10000}
                        // ref={props.curl}
                        value={props.curl}
                        onChange={handleCurlChange}
                        autoSize={{ minRows: 5, maxRows: 12 }}
                        placeholder='Please paste cURL!'
                    // onChange={handleCurlChange}
                    ></Input.TextArea>
                </Form.Item>

                <Form.Item name="switch_button" label="自动提交" valuePropName="checked" wrapperCol={{ offset: 0, span: 16 }}>
                    <Space>
                        <Switch checked={props.auto} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} onChange={props.switchAutoSubmit} />
                        {button}
                    </Space>
                </Form.Item>
            </Form>
        </>
    );
};

export default CurlFormFunc;