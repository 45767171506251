import React from 'react';
import { Alert, message } from 'antd';
import PropTypes from 'prop-types';
import OptionBar from './OptionBar';
import CodeInputs from './CodeInputs';
import AlertBar from './AlertBar';

var Config = require('Config')

const defaultCheckedList = ['golang', 'proto', 'sql', 'toml', 'gostruct'];

class MainContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            lastChange: '',
            checkedList: ['golang', 'sql', 'toml', 'yaml', 'json'
                // 'javascript', 'php', 
                // 'proto', 'curl'
            ],
            mapping: {
                'golang': "go",
                'proto': "protobuf",
                'sql': "sql",
                'toml': "toml",
                'json': "json",
                // 'javascript': 'javascript',
                "yaml": "yaml",
                'xml': 'xml',
                "curl": "shell"
            },
            options: {
                golang: { label: 'golang', value: 'go', code: btoa(encodeURIComponent('\ntype Person struct {\n  Name string `json:"name"`\n  Age  int    `json:"age"`\n  // 性别, true->man, false->woman\n  Sex bool `json:"sex"`\n  // 体重\n  Weight float64 `json:"weight"`\n  // 爱好\n  Hobby []string `json:"hobby"`\n}')) },
                proto: { label: 'proto', value: 'proto', code: btoa(encodeURIComponent("syntax=\"proto3\";\n\nmessage Person  {\n\tint32 age = 1;\n\tstring name = 2;\n}")) },
                sql: { label: 'sql', value: 'sql', code: btoa(encodeURIComponent("CREATE TABLE `admin_users` (\n	`id` int(10) unsigned NOT NULL AUTO_INCREMENT COMMENT '主键ID',\n	`username` varchar(190) COLLATE utf8mb4_unicode_ci NOT NULL DEFAULT '' COMMENT '用户名',\n	`password` varchar(60) COLLATE utf8mb4_unicode_ci NOT NULL DEFAULT '' COMMENT '密码',\n	`name` varchar(255) COLLATE utf8mb4_unicode_ci NOT NULL DEFAULT '' COMMENT '昵称',\n	`avatar` varchar(255) COLLATE utf8mb4_unicode_ci DEFAULT NULL COMMENT '头像',\n	`remember_token` varchar(100) COLLATE utf8mb4_unicode_ci DEFAULT NULL COMMENT 'token',\n	`created_at` timestamp NULL DEFAULT NULL COMMENT '数据创建于',\n	`updated_at` timestamp NULL DEFAULT NULL COMMENT '上次更新时间',\n	PRIMARY KEY (`id`),\n	UNIQUE KEY `admin_users_username_unique` (`username`)\n) ENGINE=InnoDB AUTO_INCREMENT=2 DEFAULT CHARSET=utf8mb4 COLLATE=utf8mb4_unicode_ci;")) },
                // javascript: { label: 'javascript', value: 'javascript', code:  btoa("function hello() {\n\tconsole.log(\"Hello!\");\n}")},
                toml: { label: 'toml', value: 'toml', code: btoa(encodeURIComponent("[Config]\n\tdsn=\"mysql:roo@127.0.0.1:3306\"")) },
                yaml: { label: 'yaml', value: 'yaml', code: btoa(encodeURIComponent('apiVersion: v1\nkind: Service\nmetadata:\n  name: nginxsvc\n  labels:\n    app: nginx\nspec:\n  ports:\n  - port: 80\n    protocol: TCP\n  selector:\n    app: nginx')) },
                json: { label: 'json', value: 'json', code: btoa(encodeURIComponent('{\n\t"login": "octocat",\n\t"id": 1,\n\t"avatar_url": "https://github.com/images/*.png"\n}')) },
                xml: { label: 'xml', value: 'xml', code: btoa(encodeURIComponent('<html>\n  <head>\n    <meta charset="utf-8" />\n    <title>Go-Tools工具集(itjsz.com)</title>\n  </head>\n  <body>\n    <h1>这是个丰富的转换工具</h1>\n    <p>尝试转换点东西吧～</p>\n  </body>\n</html>')) },
                // go: { label: 'go', value: 'gostruct', code: btoa(encodeURIComponent("func main() {\n\tfmt.Printf(\"Hello World.\");\n}")) },
                curl: { label: 'curl', value: 'shell', code: btoa(encodeURIComponent('curl -u "demo" -X POST -d @file1.txt -d @file2.txt https://example.com/upload')) }
            },
            plainOptions: ['golang', 'proto', 'sql', 'toml', 'yaml', 'json', 'xml', 'curl'],
            msg: "",
            alertType: "Error",
        }

        this.onDo = this.onDo.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCodeChange = this.onCodeChange.bind(this);
        this.onCallChange = this.onCallChange.bind(this);
        this.onCloseAlert = this.onCloseAlert.bind(this);
        this.onCodeLastChange = this.onCodeLastChange.bind(this);

        window.onCallChange = this.onCallChange;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // let changed = JSON.stringify(this.state) == JSON.stringify(prevState);
        // console.log(changed);
    }

    // 选择勾选项目。例如：curl，golang，json，sql
    onChange(list) {
        let hasCurl = false
        list.forEach(element => {
            if ("curl" == element) {
                hasCurl = true;
            }
        });
        if (hasCurl) {
            list = ["golang", "curl"];
        }
        this.setState({ checkedList: list, lastChange: '' });
    }

    onDo(e) {
        var lan = this.state.lastChange;
        let newRes = this.state.checkedList.reduce((acc, curr) => (acc[curr] = true, acc), {});
        let thisLabel = { label: lan, value: lan, code: "" }
        if ("" == (this.state.lastChange)) {
            message.error('请选择和填入你要转换的内容', 5);
            return
        } else if ("json" == this.state.lastChange) {
            var newValue = this.state.options.json.code;
            thisLabel.code = newValue;
            var realValue = decodeURIComponent(atob(newValue));
            var theBody = JSON.stringify({ option: { syntax: "json" }, checked: newRes, json: realValue })
        } else if ("toml" == this.state.lastChange) {
            var newValue = this.state.options.toml.code;
            thisLabel.code = newValue;
            newValue = decodeURIComponent(atob(newValue));
            var theBody = JSON.stringify({ option: { syntax: "toml" }, checked: newRes, toml: newValue })
        } else if ("yaml" == this.state.lastChange) {
            var newValue = this.state.options.yaml.code;
            thisLabel.code = newValue;
            newValue = decodeURIComponent(atob(newValue));
            // 制表符号替换为空格
            newValue = newValue.replace(/\t/g, "  ");
            var theBody = JSON.stringify({ option: { syntax: "yaml" }, checked: newRes, yaml: newValue })
        } else if ("xml" == this.state.lastChange) {
            var newValue = this.state.options.xml.code;
            thisLabel.code = newValue;
            newValue = decodeURIComponent(atob(newValue));
            var theBody = JSON.stringify({ option: { syntax: "xml" }, checked: newRes, xml: newValue })
        } else if ("curl" == this.state.lastChange) {
            var newValue = this.state.options.curl.code;
            thisLabel.code = newValue;
            newValue = decodeURIComponent(atob(newValue));
            var theBody = JSON.stringify({ option: { syntax: "curl" }, checked: newRes, curl: newValue })
        } else if ("golang" == this.state.lastChange) {
            var newValue = this.state.options.golang.code;
            thisLabel.code = newValue;
            newValue = decodeURIComponent(atob(newValue));
            var theBody = JSON.stringify({ option: { syntax: "golang" }, checked: newRes, code: newValue })
        } else if ("sql" == this.state.lastChange) {
            var newValue = this.state.options.sql.code;
            thisLabel.code = newValue;
            newValue = decodeURIComponent(atob(newValue));
            var theBody = JSON.stringify({ option: { syntax: "sql", commentOn: "up", gormTagOn: true, xormTagOn: true }, checked: newRes, sql: newValue })
        } else if ("proto" == this.state.lastChange) {
            var newValue = this.state.options.proto.code;
            thisLabel.code = newValue;
            newValue = decodeURIComponent(atob(newValue));
            var theBody = JSON.stringify({ option: { syntax: "proto" }, checked: newRes, proto: newValue })
        }

        this.onCloseAlert();
        this.setState({ loading: true });
        fetch(Config.serverUrl + '/xx2xx', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: theBody
        }).then(res => res.json()).then((result) => {
            let newState = this.state;
            if (result.code != 200) {
                this.newAlert(result.msg);
                return console.log("request return err code " + result.code)
            }
            var data = result.data
            if (true == data.respOption.golang) {
                let newStateStr = { label: "golang", value: "go", code: btoa(encodeURIComponent(data.code)) }
                newState.options["golang"] = newStateStr;
            }
            if (true == data.respOption.json && 0 < data.json.length) {
                let jsonValueStr = JSON.parse(data.json)
                let newStateStr = { label: "json", value: "json", code: btoa(encodeURIComponent(JSON.stringify(jsonValueStr, null, "  "))) }
                newState.options["json"] = newStateStr;
            }
            if (true == data.respOption.toml) {
                let newStateStr = { label: "toml", value: "toml", code: btoa(encodeURIComponent(data.toml)) }
                newState.options["toml"] = newStateStr;
            }
            if (true == data.respOption.yaml) {
                let newStateStr = { label: "yaml", value: "yaml", code: btoa(encodeURIComponent(data.yaml)) }
                newState.options["yaml"] = newStateStr;
            }
            if (true == data.respOption.xml) {
                let newStateStr = { label: "xml", value: "xml", code: btoa(encodeURIComponent(data.xml)) }
                newState.options["xml"] = newStateStr;
            }
            if (true == data.respOption.proto) {
                let newStateStr = { label: "proto", value: "proto", code: btoa(encodeURIComponent(data.proto)) }
                newState.options["proto"] = newStateStr;
            }
            if (true == data.respOption.sql) {
                let newStateStr = { label: "sql", value: "sql", code: btoa(encodeURIComponent(data.sql)) }
                newState.options["sql"] = newStateStr;
            }

            newState.lastChange = lan;
            newState.options[lan] = thisLabel;
            newState.loading = false;
            this.setState(newState);
            this.setState({ lastChange: lan });
        },
            // 注意：需要在此处处理错误
            // 而不是使用 catch() 去捕获错误
            // 因为使用 catch 去捕获异常会掩盖掉组件本身可能产生的 bug
            (error) => {
                this.setState({
                    isLoaded: true,
                    loading: false,
                    error
                });
            }
        );  // end fetch
    }

    onCallChange(argv) {
        return;
        let newGoCode = argv[0];
        let lan = argv[1];
        if ('sql' == lan) {
            // var newGoCode = window.onSql2Go(newValue);

            var jsonValue = btoa(encodeURIComponent(newGoCode));
            let tolan = 'golang';
            let newState = this.state;
            var newStateStr = "{\"label\": \"" + tolan + "\", \"value\": \"" + tolan + "\", \"code\": \"" + jsonValue + "\"}"
            newState.options[tolan] = JSON.parse(newStateStr);

            this.setState(newState);
        }
    }

    onCodeChange(newValue, e, lan) {
        let baseVal = btoa(encodeURIComponent(newValue))
        let thisLabel = { label: lan, value: lan, code:  baseVal}
        if ("json" == lan) {
            var theBody = JSON.stringify({ option: { syntax: "json" }, json: newValue })
        } else if ("toml" == lan) {
            var theBody = JSON.stringify({ option: { syntax: "toml" }, toml: newValue })
        } else if ("yaml" == lan) {
            var theBody = JSON.stringify({ option: { syntax: "yaml" }, toml: newValue })
        } else if ("xml" == lan) {
            var theBody = JSON.stringify({ option: { syntax: "xml" }, toml: newValue })
        } else if ("curl" == lan) {
            var theBody = JSON.stringify({ option: { syntax: "curl" }, curl: newValue })
        } else if ("golang" == lan) {
            var theBody = JSON.stringify({ option: { syntax: "golang" }, go: newValue })
        } else if ("sql" == lan) {
            var theBody = JSON.stringify({ option: { syntax: "sql", commentOn: "up", gormTagOn: true, xormTagOn: true }, sql: newValue })
        } else if ("proto" == lan) {
            var theBody = JSON.stringify({ option: { syntax: "proto" }, proto: newValue })
        }
        let newState = this.state;
        let changed = baseVal == this.state.options[lan].code
        if (!changed) {
            newState.lastChange = lan;
        }
        newState.options[lan] = thisLabel;
        this.setState(newState);
    }

    onCodeLastChange(lan) {
        this.setState({ lastChange: lan });
    }

    showAlert(alertType, msg, callBack) {
        if (undefined == msg || 1 > msg.length) {
            return (
                <>
                </>
            )
        } else {
            return (
                <>
                    <AlertBar msg={msg} alertType={alertType} onClose={callBack} />
                </>
            )
        }
    }

    onCloseAlert() {
        let newState = this.state;
        newState.msg = "";
        this.setState(newState)
    }

    newAlert(msg = "", alertType = "Error") {
        let newState = this.state;
        newState.msg = msg;
        newState.alertType = alertType;
        newState.loading = false;
        this.setState(newState);
    }

    render() {
        if (/\/\w+/.test(String(this.props.pathname))) {
            return (<></>)
        }
        return (
            <>
                <AlertBar msg={this.state.msg} alertType={this.state.alertType} closeCallBack={this.onCloseAlert} />
                <OptionBar checkedList={this.state.checkedList} plainOptions={this.state.plainOptions} loading={this.state.loading}
                    onChange={this.onChange} defaultCheckedList={defaultCheckedList} onDo={this.onDo} />
                <CodeInputs checkedList={this.state.checkedList} mapping={this.state.mapping} current={this.state.lastChange}
                    options={this.state.options} onCodeChange={this.onCodeChange} onCodeLastChange={this.onCodeLastChange} />
            </>
        )
    }
}

export default MainContent;