import React from 'react';
import { Space, Layout } from 'antd';
import ReactMarkdown from 'react-markdown';

import qrcodepng from '../qrcode.jpg';

const { Header, Content, Footer } = Layout;

// 关于，说明
class About extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            content: `# 工具集
## Go Struct、Proto、Json... 多种格式批量转换

这个工具，目前支持:

* go struct -> proto、toml、yaml、json、xml
* proto -> go struct、toml、yaml、json、xml
* toml -> go struct、proto、yaml、json、xml
* yaml -> go struct、proto、toml、json、xml
* json -> go struct、proto、toml、yaml、xml
* xml -> go struct、proto、toml、yaml、json
* sql -> go struct、proto、toml、yaml、json、xml
* curl -> golang code 

当前网上大部分工具是 toml -> yaml, sql -> json , 1对1的转换工具。

由于我工作需要：通过sql同时生成 proto、go struct 代码、toml、yaml（配置文件), 用其他 1对1 转换工具，需要转换几次才能拿到结果。于是就做了这个1转7集合工具。

为了应用更广泛，后面有陆续引入了 json、xml。

### 转换工具使用方法

打开页面后，编辑框里面的文本是事例，你需要编辑事例代码，或者粘贴你需要转换的代码到对应的编辑框内。然后点击转换，就开始转换了。

## cURL 转换工具

我主要工作是后端，遇到有同事反馈问题，我可能会这样解决：

1. 让同事给我curl
2. 把host、port改成本地的
3. 本地起服务
4. 调用本地的服务，打断点定位问题。

如果遇到问题反复，那么久就会有频繁手动修改curl的动作，于是自己做了个工具，来自动化编辑curl。使之能调用本地的服务。

### 如何转换curl

打开页面后，有有左右两个表单，先把你从线上或测试环境copy的curl粘贴到左下角的cURL表单里，程序会自动解析填充到其他表单里。

然后在右边的表单里填上你本地的，http协议，hostName，本地服务的端口号。如果线上或测试环境的请求还被nginx做了重写path，那么填入path的转换规则即可——正则替换。

填好规则后，点击左下脚的按钮就是把左边的按照规则转换到右边去，点击后，你会在右下角文本框内，收到调用本地服务的cURL。

## 各语言语法对照

有做全栈的朋友么？

* 语言太多，API太多
* 工作时各各语言频繁切换。
* 我想遍历个数组对象，这个语言用 for each，这个语言叫 loop，还有用 \`for in\`的...
* ...

于是我就想做这个网站，更或者，我想称它为——各语言语法速查。当你忘了，或者不确定该怎么敲代码时，来看看。

同时，在我掌握一门语言时，还能快速通过语法对照，触类旁通其他语言。

哦，对了，语法对照这个功能，依赖大量的文档，目前才刚起步

## 问题反馈

有任何疑难问题，或者建议，可以通过公众号：gophitjsz 联系到我。

微信公众号二维码：

![qrcode](http://cdn.itjsz.com/qrcode_for_gophitjsz.jpg)
`
        }
    }

    render() {
        let contentStyle = { margin: '30px 50px' }

        return (
            <Content style={{ padding: '50px 50px' }}>
                <div className="site-layout-content">
                    <ReactMarkdown>{this.state.content}</ReactMarkdown>
                </div>
            </Content>
        )
    }
}

export default About;